import Carousel from "./modules/carousel";
import Menu from "./modules/menu";
import Tab from "./modules/tab";
import AjaxSearch from "./modules/ajaxSearch";
import FileUploadCheck from "./modules/fileUploadCheck";

document.querySelector("html").classList.remove("no-js");

if (document.querySelector(".form--backend[data-action='add']")) {
  new FileUploadCheck();
}

if (document.querySelector("#search")) {
  new AjaxSearch();
}

if (document.querySelector(".menu")) {
  new Menu();
}
if (document.querySelector(".carousel")) {
  new Carousel();
}

if (document.querySelector(".tab")) {
  new Tab();
}
