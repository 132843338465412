class FileUploadCheck {
  constructor() {
    this.DOM = {
      uploadForm: document.querySelector(".form--backend"),
      uploadButton: document.querySelector(
        '.form--backend button[type="submit"]'
      ),
      uploadFile1: document.querySelector("#preview1"),
      uploadFile2: document.querySelector("#preview2"),
      uploadFile3: document.querySelector("#preview3")
    };
    this.ObserveDOM();
  }

  insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  ObserveDOM() {
    window.addEventListener("load", () => {
      if (
        this.DOM.uploadFile1.value === "" &&
        this.DOM.uploadFile2.value === "" &&
        this.DOM.uploadFile3.value === ""
      ) {
        this.DOM.uploadButton.disabled = true;
      }
    });
    this.DOM.uploadFile1.addEventListener(
      "input",
      e => {
        // console.log(e.target.value);
        if (
          this.DOM.uploadFile1.value !== "" &&
          this.DOM.uploadFile2.value !== "" &&
          this.DOM.uploadFile3.value !== ""
        ) {
          this.DOM.uploadButton.disabled = false;
        }
      },
      this
    );
    this.DOM.uploadFile2.addEventListener(
      "input",
      e => {
        // console.log(e.target.value);
        if (
          this.DOM.uploadFile1.value !== "" &&
          this.DOM.uploadFile2.value !== "" &&
          this.DOM.uploadFile3.value !== ""
        ) {
          this.DOM.uploadButton.disabled = false;
        }
      },
      this
    );
    this.DOM.uploadFile3.addEventListener(
      "input",
      e => {
        // console.log(e.target.value);
        if (
          this.DOM.uploadFile1.value !== "" &&
          this.DOM.uploadFile2.value !== "" &&
          this.DOM.uploadFile3.value !== ""
        ) {
          this.DOM.uploadButton.disabled = false;
        }
      },
      this
    );
  }
}

export default FileUploadCheck;
