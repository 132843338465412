class AjaxSearch {
  constructor() {
    this.DOM = {
      searchForm: document.querySelector(".search-form"),
      searchField: document.querySelector("#search"),
      searchButton: document.querySelector('.search-form button[type="submit"]')
    };
    this.ObserveDOM();
  }

  createSearchList() {
    const searchField = this.DOM.searchField;
    const dataList = document.createElement("datalist");

    dataList.id = "ajax-list";
    searchField.setAttribute("list", "ajax-list");
    this.insertAfter(dataList, searchField);
    this.DOM.dataList = dataList;
  }

  fetchSearchData(search) {
    fetch("index.php?action=ajaxsearch", {
      method: "POST",
      body: new URLSearchParams("like=" + search)
    })
      .then(res => res.json())
      .then(res => {
        console.log(this);
        this.setSearchResult(res);
      })
      .catch(e => console.error("Error: " + e));
  }

  setSearchResult(data) {
    const dataList = this.DOM.dataList;
    // console.log("data:", data);
    dataList.innerHTML = "";
    Array.from(data).forEach(obj => {
      const option = document.createElement("option");
      const value = Object.entries(obj)[0][1];

      option.innerHTML = value;
      option.setAttribute("value", value);
      dataList.appendChild(option);
    });
  }

  insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  ObserveDOM() {
    window.addEventListener("load", () => {
      this.createSearchList();
      if (this.DOM.searchField.value === "") {
        this.DOM.searchButton.disabled = true;
      }
    });
    this.DOM.searchField.addEventListener(
      "input",
      e => {
        // console.log(e.target.value);
        if (e.target.value === "") {
          this.DOM.searchButton.disabled = true;
        } else {
          this.DOM.searchButton.disabled = false;
        }
        this.fetchSearchData(e.target.value);
      },
      this
    );
  }
}

export default AjaxSearch;
