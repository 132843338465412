class Menu {
  constructor() {
    this.state = "bar"; // bar, burger, expanded
    this.UI = {
      menu: document.querySelector(".menu"),
      menuItems: Array.from(document.querySelectorAll(".menu__item")),
      burgerBar: document.querySelector(".burger-bar"),
      burgerToggler: document.getElementById("burger-toggler"),
      burgerLines: Array.from(document.querySelectorAll(".burger-bar__line"))
    };

    this.observeUI();
  }

  getUI() {
    return this.UI;
  }

  aniMenu(type) {
    this.getUI().menu.style.animation = type;
  }

  removeAni() {
    this.getUI().menu.style.animation = null;
  }

  aniMenuItems() {
    Array.from(this.getUI().menuItems).forEach((item, index) => {
      item.style.animation = item.style.animation
        ? null
        : (item.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 +
            0.5}s`);
    });
  }

  getState() {
    return this.state;
  }

  setState(state) {
    this.state = state;
  }

  stateToUI() {
    const state = this.getState();
    let UI = this.getUI();

    const stateToBurger = () => {
      Array.from(this.getUI().burgerLines).forEach(line =>
        line.classList.remove("expanded")
      );
      UI.menu.style.display = "none";
      UI.menu.classList.remove("menu--horizontal");
      UI.menu.classList.add("menu--vertical");
    };

    const stateToExpanded = () => {
      Array.from(this.getUI().burgerLines).forEach(line =>
        line.classList.toggle("expanded")
      );

      UI.menu.style.display = "block";
    };

    const stateToBar = () => {
      UI.burgerBar.display = "none";
      UI.menu.style.display = "flex";
      UI.menu.classList.add("menu--horizontal");
      UI.menu.classList.remove("menu--vertical");

      this.getUI().burgerToggler.checked = false;
    };

    switch (state) {
      case "burger":
        stateToBurger();
        break;
      case "expanded":
        stateToExpanded();
        break;
      case "bar":
        stateToBar();
        break;
    }
  }

  observeUI() {
    const checkWidth = () => {
      if (window.innerWidth > 930) this.setState("bar");
      else if (window.innerWidth < 930) this.setState("burger");
      else this.setState("expanded");

      this.stateToUI();
    };

    window.addEventListener("load", checkWidth);
    window.addEventListener("resize", checkWidth);

    this.getUI().burgerToggler.addEventListener("click", e => {
      console.log(e);
      if (e.target.checked) this.setState("expanded");
      else this.setState("burger");

      this.stateToUI();
    });
  }
}

export default Menu;
