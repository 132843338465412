class Tab {
  constructor() {
    this.activeTabIndex = 0;
    this.DOM = {
      articles: document.querySelectorAll("article.tab__article"),
      navItems: document.querySelectorAll("li.tab__item"),
      navList: document.querySelector("ul.tab__list"),
      deactivateTabs: () =>
        this.DOM.navItems.forEach(el => el.classList.remove("active")),
      activateTab: index => this.DOM.navItems[index].classList.add("active"),
      hideArticles: () =>
        this.DOM.articles.forEach(el => (el.style.display = "none")),
      showArticle: index => (this.DOM.articles[index].style.display = "block")
    };
    // this.setActiveTab(0);
    this.ObserveDOM();
  }

  getActiveTab() {
    return this.activeTabIndex;
  }

  setActiveTab(index) {
    this.activeTabIndex = index;
    this.stateToDOM();
  }

  stateToDOM() {
    this.DOM.deactivateTabs();
    this.DOM.activateTab(this.getActiveTab());
    this.DOM.hideArticles();
    this.DOM.showArticle(this.getActiveTab());
  }

  ObserveDOM() {
    window.addEventListener("load", () => this.setActiveTab(0));

    this.DOM.navList.addEventListener("click", e =>
      this.setActiveTab(Array.from(this.DOM.navItems).indexOf(e.target))
    );
  }
}

export default Tab;
