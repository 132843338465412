class Carousel {
  constructor() {
    this.slideIndex;
    this.DOM = {
      trackContainer: document.querySelector(".carousel__track-container"),
      slides: Array.from(document.querySelectorAll(".carousel__slide")),
      nextButton: document.querySelector(".carousel__button--next"),
      prevButton: document.querySelector(".carousel__button--prev"),
      // captions: document.querySelectorAll('.carousel__caption'),
      dotsNav: document.querySelector(".carousel__nav"),
      dots: Array.from(document.querySelectorAll(".carousel__indicator")),

      showSlide: i => {
        const hideSlides = () =>
          this.DOM.slides.forEach(slide => {
            slide.classList.add("hide");
            slide.classList.remove("show");
          });
        hideSlides();
        this.DOM.slides[i].classList.remove("hide");
        this.DOM.slides[i].classList.add("show");
      },

      setIndicator: i => {
        const removeIndicators = () =>
          this.DOM.dots.forEach(dot => dot.classList.remove("current-slide"));
        removeIndicators();
        this.DOM.dots[i].classList.add("current-slide");
      },

      disableButton: btn => {
        const resetButton = btn => {
          this.DOM[btn].disable = false;
          this.DOM[btn].classList.remove("disable");
        };
        resetButton("nextButton");
        resetButton("prevButton");
        this.DOM[btn].disable = true;
        this.DOM[btn].classList.add("disable");
      }
    };
    this.ObserveDOM();
  }

  autoSlide(sec) {
    return setInterval(() => {
      this.setSlideIndex(
        this.getSlideIndex() === this.DOM.slides.length - 1
          ? 0
          : this.getSlideIndex() + 1
      );
    }, sec);
  }

  isFirstSlide(i) {
    return i === 0;
  }

  isLastSlide(i) {
    return i === this.DOM.slides.length - 1;
  }

  getSlideIndex() {
    return this.slideIndex;
  }

  setSlideIndex(i) {
    if (i >= this.DOM.slides.length)
      this.slideIndex = this.DOM.slides.length - 1;
    else if (i < 0) this.slideIndex = 0;
    else this.slideIndex = i;

    this.stateToDOM();
  }

  stateToDOM() {
    this.DOM.showSlide(this.getSlideIndex());

    this.DOM.setIndicator(this.getSlideIndex());

    if (this.isLastSlide(this.getSlideIndex()))
      this.DOM.disableButton("nextButton");

    if (this.isFirstSlide(this.getSlideIndex()))
      this.DOM.disableButton("prevButton");
  }

  ObserveDOM() {
    let handle;
    window.addEventListener("load", () => {
      this.setSlideIndex(Math.floor(Math.random() * this.DOM.slides.length));
      handle = this.autoSlide(8000);
    });

    this.DOM.nextButton.addEventListener("click", e =>
      this.setSlideIndex(this.getSlideIndex() + 1)
    );

    this.DOM.prevButton.addEventListener("click", e =>
      this.setSlideIndex(this.getSlideIndex() - 1)
    );

    this.DOM.dots.forEach(dot =>
      dot.addEventListener("click", e =>
        this.setSlideIndex(e.target.id.slice(-1))
      )
    );

    this.DOM.trackContainer.addEventListener("mouseover", () => {
      clearInterval(handle);
      handle = 0;
    });

    this.DOM.trackContainer.addEventListener("mouseout", () => {
      handle = this.autoSlide(8000);
    });
  }
}

export default Carousel;
